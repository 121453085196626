import { useState, useEffect, useCallback } from "react";
import Typography from "../../components/common/_typography";
import { Col, Container, Row } from "reactstrap";
// import CommonManufacturerCards from "../../components/onBoardingComponents/_deviceManufacturerCard";
import SearchBox from "../../components/onBoardingComponents/_searchBox";
import DeviceListToConfigure from "../../components/onBoardingComponents/_deviceListToConfigure";
import axiosAdapter from "../../untils";
import { env } from "../../env";
import SkeletonLoading from "../../components/common/_skeletonLoading";

const OnBoardingDeviceManufacturer = (props) => {
  const { handleDeviceCardSelection, handleDeviceSelected } = props;
  const [deviceList, setDeviceList] = useState([]);
  const [backUpDeviceList, setBackUpDeviceList] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCardSelect = useCallback((id, name, manufact_name) => {
    handleDeviceSelected(name, manufact_name);
    if (id === selectedCardId) {
      // Deselect the card if it is already selected
      handleDeviceCardSelection(false);
      setSelectedCardId(null);
    } else {
      // Select the clicked card
      setSelectedCardId(id);
      handleDeviceCardSelection(true);
    }
  }, []);

  useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = async () => {
    setIsLoading(true);
    //API Call: Validating OTP and Resetting Password.
    let stringifiedData = JSON.stringify({
      manufacturerId: 31,
    });
    let fetchedDevices = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "admin/getDevicesForManufacturer",
      stringifiedData
    );
    setDeviceList(fetchedDevices.data.data);
    setBackUpDeviceList(fetchedDevices.data.data);
    setIsLoading(false);
  };

  const handleSearch = (searchedWord) => {
    if (searchedWord === "") {
      setDeviceList(backUpDeviceList);
    } else {
      if (deviceList.length == 0) {
        const newFilteredObjects = backUpDeviceList.filter((obj) =>
          obj.device_name.toLowerCase().includes(searchedWord.toLowerCase())
        );

        setDeviceList(newFilteredObjects);
      } else {
        setDeviceList(backUpDeviceList);
        const newFilteredObjects = backUpDeviceList.filter((obj) =>
          obj.device_name.toLowerCase().includes(searchedWord.toLowerCase())
        );

        setDeviceList(newFilteredObjects);
      }
    }
  };

  return (
    <Container fluid>
      <Col xs={12} className="onBoardingPipeLineSubTitle">
        <Col xs={9}>
          <Typography
            text={"Select the Device Model"}
            tag={"head-x-small"}
            color={"#FAFAFA"}
          />
        </Col>
        <Col xs={3} className="onBoardingPipeLineSearchBoxStyle">
          <SearchBox
            placeholder={"Search for Device Models"}
            handleSearch={handleSearch}
          />
        </Col>
      </Col>

      <Col xs={12} className="deviceListConfigSkeleton">
        {isLoading ? (
          <SkeletonLoading height={"50vh"} />
        ) : (
          <Col xs={12} className="onBoardingManufactureCardStyle">
            <Col xs={12} className="onBoardingCardsFlex">
              {deviceList.map((eachListItem) => (
                <Col className="onBoardingCardMultiplierStyle">
                  <DeviceListToConfigure
                    name={eachListItem.device_name}
                    id={eachListItem.id}
                    icon_link={eachListItem.icon_link}
                    manufact_name={eachListItem.manufact_name}
                    isSelected={eachListItem.id === selectedCardId}
                    onSelect={handleCardSelect}
                  />
                </Col>
              ))}
            </Col>
          </Col>
        )}
      </Col>
    </Container>
  );
};
export default OnBoardingDeviceManufacturer;
