import { Col, Container, Row } from "reactstrap";
import Button from "../../components/common/_button";
import Typography from "../../components/common/_typography";

const ButtonLandingPage = (props) => {
	const handleSubmitClick = () => {
		props.onClickEvent();
	};
	return (
		<div>
			<Col xs={12}>
				<Button
					tag={"large-primary"}
					disabled={props.disabled}
					onClickEvent={handleSubmitClick}
					btnText={props.buttonPlaceholder}
				/>
				<Col xs={12} className="contactusMargin">
					<Typography
						tag={"head-x-x-small"}
						color={" #FAFAFA;"}
						text={props.linkLable}
					/>
				</Col>
			</Col>
		</div>
	);
};

export default ButtonLandingPage;
