import { Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import styled from "styled-components";
import Typography from "../common/_typography";
import ConfigureInputField from "./_configureInputField";

const ConfigureCard = (props) => {
  const Card = styled.div`
    width: 100%;
    height: 538px;
    background: #313131;
    overflow: auto;
  `;
  const Line = styled.div`
    height: 0px;
    border: 2px solid #909090;
  `;
  const { deviceName } = props;
  return (
    <Card>
      <Container className="configureCardContainer">
        <Row>
          <Col xs={12}>
            <Typography
              text={`Configure ${deviceName}`}
              color={"#FAFAFA"}
              tag={"head-x-x-small"}
            />
          </Col>
        </Row>
        <Row className="configureLineRow">
          <Col xs={12}>
            <Line />
          </Col>
        </Row>
        <Row className="configureFormRow">
          <Form>
            <FormGroup row>
              <Label for="dataSource" sm={3}>
                <Typography
                  text="Data Source Name*"
                  color={"#FAFAFA"}
                  tag={"p-x-small"}
                />
              </Label>
              <Col sm={9}>
                <ConfigureInputField placeholder="Name of the Data Source" />
                <div className="configureInputDescription">
                  <Typography
                    text="Each replicated schema is prefixed with aurora_postgres_. This prefix cannot be changed after you test the connector or save for later."
                    color={"#FAFAFA"}
                    tag={"p-x-small"}
                  />
                </div>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="destinationSchema" sm={3}>
                <Typography
                  text="Destination Schema Prefix*"
                  color={"#FAFAFA"}
                  tag={"p-x-small"}
                />
              </Label>
              <Col sm={9}>
                <ConfigureInputField placeholder="Enter the Destination Schema Prefix" />

                <div className="configureInputDescription">
                  <Typography
                    text="Each replicated schema is prefixed with aurora_postgres_. This prefix cannot be changed after you test the connector or save for later."
                    color={"#FAFAFA"}
                    tag={"p-x-small"}
                  />
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="host" sm={3}>
                <Typography text="Host*" color={"#FAFAFA"} tag={"p-x-small"} />
              </Label>
              <Col sm={9}>
                <ConfigureInputField placeholder="IP (1.2.3.4) or domain (your.server.com)" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="port" sm={3}>
                <Typography text="Port*" color={"#FAFAFA"} tag={"p-x-small"} />
              </Label>
              <Col sm={9}>
                <ConfigureInputField placeholder="Enter the Port Number" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="username" sm={3}>
                <Typography
                  text="Username*"
                  color={"#FAFAFA"}
                  tag={"p-x-small"}
                />
              </Label>
              <Col sm={9}>
                <ConfigureInputField placeholder="Enter the Username" />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="password" sm={3}>
                <Typography
                  text="Password*"
                  color={"#FAFAFA"}
                  tag={"p-x-small"}
                />
              </Label>
              <Col sm={9}>
                <ConfigureInputField placeholder="Enter the Password" />
              </Col>
            </FormGroup>
          </Form>
        </Row>
      </Container>
    </Card>
  );
};

export default ConfigureCard;
