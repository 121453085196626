import React from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import Typography from "../common/_typography";
import SkeletonLoading from "../common/_skeletonLoading";

const DeviceListToConfigure = React.memo((props) => {
  const { name, id, isSelected, onSelect, icon_link, manufact_name } = props;

  const handleClick = () => {
    onSelect(id, name, manufact_name);
  };

  const DeviceListCards = styled.div`
    cursor: pointer;
    min-width: 214px;
    max-width: 214px;
    width: 100%;
    height: 200px;
    background: ${!isSelected ? "#232323" : " rgba(71, 204, 214, 0.10)"};
    border: ${isSelected && " 1px solid #47ccd6"};
  `;

  const DeviceListImageCards = styled.div`
    height: 126px;
    display: flex;
    background: ${!isSelected ? "#171717" : " rgba(71, 204, 214, 0.10)"};
  `;

  const DeviceListImageTitle = styled.div`
    height: 24px;
    width: 190px;
    overflow-wrap: break-word;
  `;

  return (
    <Col xs={12} key={id}>
      <Row>
        <Col xs={12} onClick={() => handleClick()}>
          <DeviceListCards>
            <Col xs={12}>
              <DeviceListImageCards>
                <Col xs={12} className="onBoardingDeviceList">
                  <img
                    src={icon_link}
                    alt="Device Image"
                    style={{ aspectRatio: 5 / 2, objectFit: "contain" }}
                    className="img-fluid"
                  />
                </Col>
              </DeviceListImageCards>

              <Col xs={12} className="onBoardingDeviceListTitle">
                <DeviceListImageTitle>
                  <Typography tag={"p-medium"} text={name} color={"#FAFAFA"} />
                </DeviceListImageTitle>
              </Col>

              <Col xs={12} className="onBoardingDeviceListDescription">
                <DeviceListImageTitle>
                  {/* <Typography
                    tag={"p-x-small"}
                    text={manufact_name}
                    color={"#FAFAFA"}
                  /> */}
                </DeviceListImageTitle>
              </Col>
            </Col>
          </DeviceListCards>
        </Col>
      </Row>
    </Col>
  );
});

export default DeviceListToConfigure;
