import { Col, Container } from "reactstrap";
import styled from "styled-components";
import Typography from "./_typography";
import React, { useContext, useEffect,useState } from "react";
import {SnackbarContext} from "../../layouts/Context/snackBarContext";

const StyledTopSnackBar = styled.div`
  position: absolute;
  width: 100%;
  height: 64px;
  left: 0px;
  top: 0px;
  background: #ea3d4a;
`;

const TopSnackBar = (props) => {
  const { message ,showMessage} = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
      const timer = setTimeout(() => {
        setOpen(false);
        showMessage('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message, showMessage]);


  return (
    <StyledTopSnackBar style={{ display: open ? 'block' : 'none' }}>
      <Container fluid>
        <Col
          xs={12}
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Typography text={message} color={"#FAFAFA"} tag={"p-bold-large"} />
        </Col>
      </Container>
    </StyledTopSnackBar>
  );
};

export default TopSnackBar;
