import Typography from "../../components/common/_typography";
import { Col, Container } from "reactstrap";

const CommonOnboardingPrgBarTitle = (props) => {
  return (
    <Container fluid>
      <Col xs={12}>
        <Col container className="onBoardingWokspaceTitle">
          <Typography text={props.title} tag={"head-small"} color={"#FAFAFA"} />
        </Col>
      </Col>
    </Container>
  );
};
export default CommonOnboardingPrgBarTitle;
