import InputField from "../../components/common/_loginInputField";
import Typography from "../../components/common/_typography";
import Button from "../../components/common/_button";
import { Col } from "reactstrap";

/**
 * NOTE: Common Component
 * @description This Component contains Input Field with single field along with a button.
 * @param {*} props
 * @return {*} Callback Function For Input Field.
 */
const CommonSingleInputField = (props) => {
  const handleSubmitClick = () => {
    props.handleClickHandler(false);
  };

  return (
    <>
      <Col xs={12} className="landingTitle">
        <Typography text={props.title} color={" #FAFAFA;"} tag={"head-small"} />
      </Col>
      <Col xs={12} className="landingDescription">
        <Col>
          <Typography
            text={props.description}
            color={" #B7B7B7;"}
            tag={"head-x-x-small"}
          />
        </Col>
      </Col>

      <Col xs={12} className="inputTextStyleOtp">
        <InputField
          throwError={!props.isUsernameValid}
          placeholder={props.placeholder}
          type={props.inputType}
          disabled={props.disabled}
          onChange={props.handleUsernameChange}
        />
      </Col>

      <Col xs={12} className="resendOtpStyle">
        <Col>
          <Typography
            text={props.inputFieldMember}
            color={" #FAFAFA;"}
            tag={"p-large"}
          />
        </Col>
      </Col>
      <Col xs={12} className="otpButtonMargin">
        <Button
          disabled={props.buttonState}
          tag={"large-primary"}
          btnText={props.buttonPlaceholder}
          onClickEvent={handleSubmitClick}
        />
      </Col>
      <Col xs={12} className="otpCancelStyle">
        <Typography
          tag={"head-x-x-small"}
          color={" #FAFAFA;"}
          text={props.linkLable}
        />
      </Col>
    </>
  );
};

export default CommonSingleInputField;
