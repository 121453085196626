import { Col, Row } from "reactstrap";
import styled from "styled-components";
import ViewIcon from "../../assets/images/visibility_true.svg";
import HideIcon from "../../assets/images/visibility_off_false.svg";
import "../../scss/main.scss";
import { useState } from "react";

const StyledInput = styled.input`
  all: unset;
  width: 400px;
  color: #fafafa;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0px 0px 0px 20px;
  height: 50px;
  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #909090;
  }
`;

const InputField = (props) => {
  const [viewPasswordIconState, setViewPasswordIconState] = useState(false);
  const [typeOfIcon, setTypeOfIcon] = useState(null);

  const handleInputField = (event) => {
    console.log("Event.target.value", event.target.value);
    let inputValue = props.onChange();
    inputValue(event.target.value);
    // return event.target.value;
  };

  const inputTextTypeHandler = (type) => {
    switch (type) {
      case "password":
        setTypeOfIcon("password");
        break;

      default:
        break;
    }
  };

  const handlePasswordIconChange = () => {
    setViewPasswordIconState(!viewPasswordIconState);
  };

  return (
    <Col
      className={
        props.throwError ? "errorLoginInputFieldStyle" : "loginInputFieldStyle"
      }
    >
      <Col
        xs={12}
        style={{
          height: "50px",
          width: "400px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Row>
          <Col
            xs={10}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <StyledInput
              name={props.name}
              value={props.value}
              autoComplete="none"
              placeholder={props.placeholder}
              onChange={props.onChange}
              type={!viewPasswordIconState && props.type}
              disabled={props.disabled}
            />
          </Col>
          {props.type && (
            <Col xs={1} className="passwordIconStyle" >
              {props.type === "password" && (
                <img
                  alt="password"
                  style={{cursor:'pointer'}}
                  onClick={handlePasswordIconChange}
                  src={
                    props.type === "password"
                      ? viewPasswordIconState
                        ? ViewIcon
                        : HideIcon
                      : null
                  }
                  width="20.15px"
                  height="19.15px"
                />
              )}
            </Col>
          )}
        </Row>
      </Col>
    </Col>
  );
};

export default InputField;
