import { Col, Container, Row } from "reactstrap";
import greenTick from "../../assets/images/greenTick.png";
import errorTick from "../../assets/images/errorTick.png";
import Typography from "../../components/common/_typography";
import Button from "../../components/common/_button";

/**
 * NOTE: Common Component
 * @description This Component contains OTP Validation.
 * @param {*} props
 * @return {*}
 */
const CommonValidationStatus = (props) => {
  const handleSubmitClick = () => {
    props.handleButtonClick();
  };

  return (
    <>
      <Col xs={10} className="successOtpIcon">
        <img
          src={props.status == "success" ? greenTick : errorTick}
          width="82.5px"
          height="82.4px"
          alt="Status"
        />
      </Col>
      <Col xs={12} className="landingDescription">
        <Typography
          text={props.title}
          color={props.status == "success" ? "#37D184" : "#EA3D4A"}
          tag={"head-small"}
        />
      </Col>

      <Col xs={12} className="successOtpDescription">
        <Col xs={12}>
          <Typography
            text={props.description}
            color={" #B7B7B7;"}
            tag={"sub-head-small"}
          />
        </Col>
      </Col>

      <Col xs={12} className="successOtpButtonMargin">
        <Button
          tag={"large-primary"}
          btnText={props.buttonPlaceholder}
          onClickEvent={() => handleSubmitClick(props.buttonPlaceholder)}
        />
      </Col>
      <Col xs={12} className="otpCancelStyle">
        <Typography
          tag={"head-x-x-small"}
          color={" #FAFAFA;"}
          text={[
            <a href={props.helperTextRoute}>
              <u>{props.helperText}</u>
            </a>,
          ]}
        ></Typography>
      </Col>
    </>
  );
};

export default CommonValidationStatus;
