import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  List,
  Row,
} from "reactstrap";
import styled from "styled-components";
import Typography from "../common/_typography";

const SetupCard = (props) => {
  const Card = styled.div`
    width: 100%;
    height: 538px;
    background: #313131;
    overflow: auto;
  `;
  const Line = styled.div`
    height: 0px;
    border: 2px solid #909090;
  `;
  return (
    <Card>
      <Container className="configureCardContainer">
        <Row>
          <Col xs={12}>
            <Typography
              text="Setup Guide"
              color={"#FAFAFA"}
              tag={"head-x-x-small"}
            />
          </Col>
        </Row>
        <Row className="configureLineRow">
          <Col xs={12}>
            <Line />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Typography
              text="
            Follow these instructions to replicate your Amazon Aurora
            PostgreSQL database to your destination using Fivetran."
              color={"#FAFAFA"}
              tag={"p-medium"}
            />
          </Col>
        </Row>
        <Row style={{ paddingTop: "16px" }} className="setupCardPrerequisites">
          <Col xs={12}>
            <Typography
              text="Prerequisites"
              color={"#FAFAFA"}
              tag={"sub-head-small"}
            />
          </Col>
          <Col xs={12} className="setupCardPrerequisites">
            <Typography
              text="To connect your Amazon Aurora PostgreSQL database to Fivetran, you need:"
              color={"#FAFAFA"}
              tag={"p-medium"}
            />
          </Col>
          <Col xs={12} className="setupCardPrerequisites">
            <List>
              <li style={{ color: "#FAFAFA" }}>
                <Typography
                  text="PostgreSQL version 9 - 14.x"
                  color={"#FAFAFA"}
                  tag={"p-medium"}
                />
              </li>
              <li style={{ color: "#FAFAFA" }}>
                <Typography
                  text="Your database host's IP (e.g., 1.2.3.4) or domain (your.server.com)"
                  color={"#FAFAFA"}
                  tag={"p-medium"}
                />
              </li>
              <li style={{ color: "#FAFAFA" }}>
                <Typography
                  text="TLS enabled on your database. Follow Amazon's TLS setup instructions to enable TLS on your database."
                  color={"#FAFAFA"}
                  tag={"p-medium"}
                />
              </li>
            </List>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Typography
              text="Setup Instructions"
              color={"#FAFAFA"}
              tag={"sub-head-small"}
            />
          </Col>
          <Col xs={12} className="setuCardInstructions">
            <Typography
              text="Step 1: Choose Connection Method (TLS Required)"
              color={"#FAFAFA"}
              tag={"p-medium"}
            />
          </Col>
          <Col xs={12} className="setuCardInstructions">
            <Typography
              text="Step 2: Choose Connection Method (TLS Required)"
              color={"#FAFAFA"}
              tag={"p-medium"}
            />
          </Col>
          <Col xs={12} className="setuCardInstructions">
            <Typography
              text="Step 3: Choose Connection Method (TLS Required)"
              color={"#FAFAFA"}
              tag={"p-medium"}
            />
          </Col>
          <Col xs={12} className="setuCardInstructions">
            <Typography
              text="Step 4: Choose Connection Method (TLS Required)"
              color={"#FAFAFA"}
              tag={"p-medium"}
            />
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default SetupCard;
