import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Controls,
  applyEdgeChanges,
  applyNodeChanges,
  MiniMap,
} from "reactflow";
import React, { useCallback, useState } from "react";
import dagre from "dagre";
import axiosAdapter from "../../untils";
import { Col } from "reactstrap";
import ConnectorsCard from "../../components/connectorsComponents/_connectorsCard";
import ConnectorCardDashed from "../../components/connectorsComponents/_connectorsCardDashed";
import "reactflow/dist/style.css";
import { useEffect } from "react";
import { env } from "../../env";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const position = { x: 10, y: 10 };
const edgeType = "smoothstep";

// {
//   id: "1",
//   type: "custom",
//   data: { label: "input" },
// },
// {
//   id: "2",
//   type: "custom",
//   data: { label: "output" },

// },
// {
//   id: "4",
//   type: "custom",
//   data: { label: "input1" },
// },
// {
//   id: "3",
//   type: "customDashed",
//   data: { label: "output1" },
// },

let initialNodes = [];

let initialEdges = [];

const nodeWidth = 400;
const nodeHeight = 150;

const getLayoutedElements = (nodes, edges) => {
  if (nodes && edges) {
    dagreGraph.setGraph({ rankdir: "LR" });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node, i) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = "left";
      node.sourcePosition = "right";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      if (node.position.x == 0 && node.position.y == 0) {
        node.position.x = 30;
        node.position.y = 30;
      } else if (node.position.x == 0) {
        node.position.x = 30;
      } else if (node.position.y == 0) {
        node.position.y = 30;
      }
      return node;
    });

    return { nodes, edges };
  }
};

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  initialNodes,
  initialEdges
);

const nodeTypes = {
  customDashed: ConnectorCardDashed,
  custom: ConnectorsCard,
};

const nodeColor = (node, e) => {
  if (node?.data?.label) {
    switch (node.data.label) {
      case "input":
        return "#6ede87";
      case "output":
        return "#6865A5";
      default:
        return "#ff0072";
    }
  }
};

const Flow = (props) => {
  const [nodes, setNodes] = useState(layoutedNodes);
  const [edges, setEdges] = useState(layoutedEdges);

  useEffect(() => {
    let alignedWorkspaceList;
    if (
      props.workspaceLayout &&
      props.workspaceLayout.node &&
      props.workspaceLayout.edges
    ) {
      alignedWorkspaceList = getLayoutedElements(
        props.workspaceLayout.node,
        props.workspaceLayout.edges
      );
      if (
        alignedWorkspaceList &&
        alignedWorkspaceList.nodes &&
        alignedWorkspaceList.edges
      ) {
        setNodes(alignedWorkspaceList.nodes);
        setEdges(alignedWorkspaceList.edges);
      }
    }
  }, [props.workspaceLayout]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    []
  );

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      connectionLineType={ConnectionLineType.SmoothStep}
    >
      <Controls />
      <MiniMap nodeColor={nodeColor} />
    </ReactFlow>
  );
};

const ConnectorsAddedComponents = (props) => {
  const [workspaceLayout, setWorkspaceLayout] = useState({});
  useEffect(() => {
    if (props.selectedWorkSpaceId) {
      localStorage.setItem("selectedWorkSpaceId", props.selectedWorkSpaceId);
    }
    async function fetchData() {
      let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
      let stringifyData = { id: selectedWorkspaceId };
      let getWorkSpace = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getWorkSpace",
        stringifyData
      );
      if (getWorkSpace && getWorkSpace.data && getWorkSpace.data.data) {
        setWorkspaceLayout(getWorkSpace.data.data.layout);
      }
    }
    fetchData();
  }, []);

  return (
    <Col xs={12} style={{ height: "calc(100vh - 80px)" }}>
      <Flow workspaceLayout={workspaceLayout} />
    </Col>
  );
};

export default ConnectorsAddedComponents;
