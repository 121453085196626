import { Col, Row, Container } from "reactstrap";
import Typography from "../../components/common/_typography";
import DataSourceCard from "../../components/common/_dataSourceCard";
import TelematicIcon from "../../assets/images/TelematicDevice.svg";
import DataBaseIcon from "../../assets/images/DataBaseIcon.svg";
import ConnectorsIcon from "../../assets/images/ConnectorsIcon.svg";

const OnBoardingDataSourcePipelineSetup = (props) => {
  const { handleSourceCardSelection, isSourceSelected } = props;
  return (
    <Col xs={12}>
      <Container fluid>
        <Col xs={12} className="onBoardingPipeLineSubTitle">
          <Typography
            text={"Select the Source of your Data"}
            tag={"head-x-small"}
            color={"#FAFAFA"}
          />
        </Col>
        <Col xs={12} className="onBoardingPipeLineDescription">
          <Typography
            text={"Choose the data source that you wish to use "}
            tag={"p-large"}
            color={"#FAFAFA"}
          />
        </Col>

        <Col xs={12} className="onBoardingConnectorCardsStyle">
          <Row>
            <Col xs={4}>
              <DataSourceCard
                name={"Telematic Devices"}
                disabled={false}
                isSourceSelected={isSourceSelected}
                handleSourceCardSelection={handleSourceCardSelection}
                iconImages={TelematicIcon}
              />
            </Col>
            <Col xs={4}>
              <DataSourceCard
                name={"Databases"}
                disabled={true}
                iconImages={DataBaseIcon}
                // handleSourceCardSelection={handleSourceCardSelection}
              />
            </Col>
            <Col xs={4}>
              <DataSourceCard
                name={"Connectors"}
                disabled={true}
                iconImages={ConnectorsIcon}
                // handleSourceCardSelection={handleSourceCardSelection}
              />
            </Col>
          </Row>
        </Col>
      </Container>
    </Col>
  );
};
export default OnBoardingDataSourcePipelineSetup;
