import { Col, Container, Row } from "reactstrap";
import BackButton from "../../components/common/_backButton";
import condenseLogo from "../../assets/images/condenseLogo.svg";
import supportImg from "../../assets/images/supportImg1.png";
import discord from "../../assets/images/discord.png";
import technicalDocs from "../../assets/images/technicalDocs.png";

import Typography from "../../components/common/_typography";
import Button from "../../components/common/_button";
import { useHistory,withRouter } from "react-router-dom";

const SupportPage = () => {
  const history = useHistory();
  const handleBackButtonClick = () => {
    history.push("/");
  };
  return (
    <div>
      <Container fluid>
        <Row xs={12}>
          <Container xs={12} className="supportScreenBackground">
            <Col xs={12} className="supportPageLandingImage">
              <Row>
                <Col xs={3} className="flexJustifyCenterSupportPage">
                  <BackButton onClickEvent={handleBackButtonClick} />
                </Col>
                <Col xs={6} className="flexJustifyCenterSupportPage">
                  <img
                    src={condenseLogo}
                    className="supportPageLogoStyle"
                    alt="Condense Logo"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="supportPageContentStyle">
              <Row>
                <Col xs={6}>
                  <Col className="supportPageImgContent1">
                    <img src={supportImg} />
                  </Col>
                  <Col className="supportTitleStyle">
                    <Typography
                      text={"Lost in the flow? Get in touch with us"}
                      tag={"head-small"}
                      color={"#FAFAFA"}
                    />
                  </Col>
                  <Col className="supportTitleStyle">
                    <Typography
                      text={
                        "We would love to hear from you, and help you resolve your queries."
                      }
                      tag={"head-x-x-small"}
                      color={"#B7B7B7"}
                    />
                  </Col>
                  <Col xs={12} className="supportButtonStyles">
                    <Row>
                      <Col xs={6}>
                        <Button
                          tag="secondary"
                          width="301px"
                          btnText="Call Us"
                        />
                      </Col>
                      <Col xs={6}>
                        <Button
                          tag="secondary"
                          width="301px"
                          btnText="Email Support"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Col>
                <Col xs={6}>
                  <Col className="supportTechnicalDocImageStyle">
                    <img src={technicalDocs} alt="technicalDocs" />
                  </Col>
                  <Col className="supportDiscordImageStyle">
                    <img src={discord} alt="discord" />
                  </Col>
                </Col>
              </Row>
            </Col>
          </Container>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter (SupportPage);
