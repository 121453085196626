import Typography from "../common/_typography";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import AccountNavigator from "../common/_accountNavigator";
import moment from "moment";

/**
 *NOTE: Common Component
 * @description This Component will show Users Profile and Account creation Date.
 * @param {*} props
 * @return {*}
 */
const ProfileCard = (props) => {
  let { eachWorkSpace } = props;

  const ProfileCardComponent = styled.div`
    width: 100%;
    height: 184px;
    background: #313131;
    margin-bottom: 24px;
    cursor: pointer;
  `;

  const handleWorkSpaceClick = (index) => {
    props.handleClickedWorkSpace(index);
  };

  return (
    <Container fluid onClick={() => handleWorkSpaceClick(eachWorkSpace.id)}>
      <ProfileCardComponent>
        <Col xs={12} className="profileCardAvatarSkeleton">
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={2} className="profileCardAvatar">
                  <AccountNavigator
                    iconText={eachWorkSpace.name.slice(0, 2).toUpperCase()}
                    iconTextColor={"black"}
                    iconTextSize={"sub-head-medium"}
                  />
                </Col>
                <Col xs={10} className="profileCardContainer">
                  <Col className="profileCardContainerTitle">
                    <Typography
                      text={eachWorkSpace.name}
                      color={"#FAFAFA"}
                      tag={"head-x-small"}
                    />
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={10}>
              <Col xs={12} className="profileCardSubTitle1">
                <Typography
                  text={`Created on ${moment
                    .unix(eachWorkSpace.createdat)
                    .format("DD MMM YY")}`}
                  color={"#909090"}
                  tag={"sub-head-small"}
                />
              </Col>
              {/* <Col xs={12} className="profileCardSubTitle2"> */}
              {/* <Typography
                  text={"No Collabrators"}
                  color={" #909090"}
                  tag={"sub-head-small"}
                /> */}
              {/* </Col> */}
            </Col>
          </Row>
        </Col>
      </ProfileCardComponent>
    </Container>
  );
};

export default ProfileCard;
