import axios from "axios";
import { useEffect, useState } from "react";
import Typography from "../../components/common/_typography";
import { Col, Container, Row } from "reactstrap";
import ProfileCard from "../../components/onBoardingComponents/_profileCard";
import axiosAdapter from "../../untils";
import { env } from "../../env";

const OnboardingWorkSpaceList = (props) => {
  const [workSpaces, setWorkSpaces] = useState([]);

  useEffect(() => {
    fetchWorkSpaces();
  }, []);
  const fetchWorkSpaces = async () => {
    let fetchWorkSpacesResponse = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getWorkSpace"
    );
    //API Call: Validating OTP and Resetting Password.

    setWorkSpaces(fetchWorkSpacesResponse.data.data);
  };

  const handleClickedWorkSpace = (workSpaceId) => {
    //Finding selected workspace
    let selectedWorkSpace = workSpaces.find(
      (eachWorkSpace) => eachWorkSpace.id === workSpaceId
    );

    props.handlingWorkSpace(workSpaceId, selectedWorkSpace.layout);
  };

  return (
    <Container fluid>
      <Col xs={12} className="workspaceCardsStyle">
        <Row>
          {workSpaces.length > 0 &&
            workSpaces.map((eachWorkSpace) => {
              return (
                <Col xs={4} key={eachWorkSpace.id}>
                  <ProfileCard
                    eachWorkSpace={eachWorkSpace}
                    handleClickedWorkSpace={handleClickedWorkSpace}
                  />
                </Col>
              );
            })}
        </Row>
      </Col>
      <br />
    </Container>
  );
};
export default OnboardingWorkSpaceList;
