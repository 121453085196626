import { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import condenseLogo from "../../assets/images/condenseLogo.svg";
import ToggleButton from "../common/_toggleButton";
import Typography from "../common/_typography";
import AccountNavigator from "../common/_accountNavigator";
import expandIcon from "../../assets/images/expandDropDown.png";

const NavBar = (props) => {
  const [iconText, setIconText] = useState("");

  useEffect(() => {
    const iconTextFromLocalStorage = localStorage.getItem("userName");

    if (iconTextFromLocalStorage.length > 2) {
      setIconText(iconTextFromLocalStorage.slice(0, 2).toUpperCase());
    } else {
      setIconText("AA");
    }
  }, []);

  const handleThemeToggler = () => {
    const body = document.querySelector("body");
    if (body.classList.contains("light-theme")) {
      body.classList.remove("light-theme");
    } else {
      body.classList.add("light-theme");
    }
  };
  return (
    <Container fluid className="navBarStyle">
      <Col
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container fluid>
          <Col xs={12} style={{ display: "flex" }}>
            <Col xs={9} className="navBarColStyle">
              <img
                src={condenseLogo}
                width="205px"
                height="46px"
                alt="condenseLogo"
              />
            </Col>
            {/* <Col xs={1} className="navBarThemeToggleButtonText">
              <Typography
                text={"Dark Mode"}
                color={"#FAFAFA"}
                tag={"p-medium"}
              />
            </Col>
            <Col xs={1} className="navBarThemeColToggleButton">
              <ToggleButton themeToggler={handleThemeToggler} />
            </Col> */}
            <Col xs={3} className="navBarThemeColAccountNavigator">
              <AccountNavigator
                iconText={iconText}
                iconTextColor={"black"}
                iconTextSize={"sub-head-medium"}
              />
              <img
                src={expandIcon}
                alt="expandIcon"
                width="24px"
                height="24px"
              />
            </Col>
          </Col>
        </Container>
      </Col>
    </Container>
  );
};
export default NavBar;
