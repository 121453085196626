import React, { useState } from "react";
import landingImg from "../../assets/images/loginSideBar.png";
import condenseLogo from "../../assets/images/condenseLogo.svg";
import { Col, Container, Row } from "reactstrap";
import Typography from "../../components/common/_typography";

/**
 *NOTE: Common Component
 * @description This Component contains Landing Page Logo and Side Bar Image.
 * @param {*} props
 * @return {*}
 */
const CommonLandingPage = (props) => {
  return (
    <div>
      <Container fluid className="landingScreenBackground">
        <Row xs={12}>
          <Col xs={5} className="landingSideBar">
            <Container
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Col xs={10}>
                <Col xs={12} className="landingImage">
                  <img
                    src={condenseLogo}
                    className="logoStyle"
                    alt="Condense Logo"
                  />
                </Col>
                <Col xs={12}>{props.children}</Col>
              </Col>
            </Container>
          </Col>
          <Col xs={7} className="landingImageAlign">
            <Container
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Col xs={12}>
                <Col xs={12} style={{ margin: "45px 0px 20px 0px" }}>
                  <Typography
                    tag={"head-small"}
                    color={"#FAFAFA"}
                    text={[
                      "Choose from a ",
                      <span style={{ color: "#8E85FF" }}>wide range</span>,
                      " of pre-built ",
                      <span style={{ color: "#FF8D40" }}>
                        <br />
                        available
                      </span>,
                      " libraries",
                    ]}
                  />
                </Col>
                <Col xs={11}>
                  <img
                    src={landingImg}
                    alt="landingMainImg"
                    className="landingMainImg"
                  />
                </Col>
              </Col>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default CommonLandingPage;
