import Button from "../../components/common/_button";
import React, { useState } from "react";
import RadioButton from "../../components/common/_radioButton";
import Typography from "../../components/common/_typography";
import CommonLandingPage from "./CommonLandingPage";
import { Col, Container, Row } from "reactstrap";
import { useHistory,withRouter } from "react-router-dom";
import ButtonLandingPage from "../LandingPages/CommonButtonLandingPage";

/**
 * NOTE: Structured Component
 * @description This Component is the basic landing page. This is the first page users will be routed to.
 * @return {*}
 */
const LandingPage = () => {
	const history = useHistory();
	const [createAccRadioButton, setCreateAccRadioButton] = useState(false);
	const [loginRadioButton, setloginRadioButton] = useState(false);

	const handleClickRadioBtn = (eleId) => {
		switch (eleId) {
			case 1:
				setCreateAccRadioButton(!createAccRadioButton);
				setloginRadioButton(false);
				break;

			case 2:
				setloginRadioButton(!loginRadioButton);
				setCreateAccRadioButton(false);
				break;
		}
	};

	const handleSubmitClick = () => {
		if (createAccRadioButton) {
			history.push("createAccount");
		}

		if (loginRadioButton) {
			history.push("login");
		}
	};

	return (
		<CommonLandingPage>
			<Col xs={12} className="landingTitle">
				<Typography
					text={"Continue to Condense X"}
					color={" #FAFAFA;"}
					tag={"head-small"}
				/>
			</Col>
			<Col xs={12} className="landingDescription">
				<Typography
					text={[
						"Please create a new account or ",
						<br />,
						"Sign in to continue."
					]}
					color={" #B7B7B7;"}
					tag={"head-x-x-small"}
				/>
			</Col>
			<Col xs={12} className="radioButtonStyle1">
				<RadioButton
					onClickEvent={() => handleClickRadioBtn(1)}
					btnTitle={"Get Started with Condense X"}
					btnDescription={"Create a new account "}
					isSelected={createAccRadioButton}
				/>
			</Col>
			<Col xs={12} className="radioButtonStyle2">
				<RadioButton
					onClickEvent={() => handleClickRadioBtn(2)}
					btnTitle={"Login to Condense X"}
					btnDescription={"Continue with the application"}
					isSelected={loginRadioButton}
				/>
			</Col>

			<Col xs={12} className="defaultLandingPageButtonStyle">
				<ButtonLandingPage
					onClickEvent={() => handleSubmitClick()}
					disabled={createAccRadioButton || loginRadioButton ? false : true}
					buttonPlaceholder={"Continue"}
					linkLable={[
						"Need more help? ",
						<b>
							<a href="/support">
								<u>Contact Us</u>
							</a>
						</b>
					]}
				/>
			</Col>
		</CommonLandingPage>
	);
};

export default withRouter(LandingPage);
