import styled from "styled-components";
import { Col } from "reactstrap";
import Typography from "./_typography";

const AccountNavigator = (props) => {
  const { iconText, iconTextColor, iconTextSize } = props;

  const AccountNavigatorIcon = styled.div`
    width: 48px;
    border-radius: 100px;
    height: 48px;
    background: #fafafa;
  `;

  return (
    <div>
      <AccountNavigatorIcon>
        <Col
          style={{
            padding: "8px 0px 0px 8px",
          }}
        >
          <Typography
            text={iconText}
            color={iconTextColor}
            tag={iconTextSize}
          />
        </Col>
      </AccountNavigatorIcon>
    </div>
  );
};

export default AccountNavigator;
