import axios from "axios";

const axiosAdapter = async (method, url,data) => {
    console.log("data",data)
  let getTokenFromStorage = localStorage.getItem("token");
  let config = {
    method: method,
    // NOTE: Need to add the dyncamic URL such that only routes shall be passed
    url: url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getTokenFromStorage}`,
    },
    ...(data && {data})
  };
  //API Call: Validating OTP and Resetting Password.
  let axiosResponse = await axios(config);
  return axiosResponse;
};

export default axiosAdapter;
