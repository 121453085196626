import { Col, Row } from "reactstrap";
import styled from "styled-components";
import GreenTickIcon from "../../assets/images/task_alt_white.svg";

const RadioButton = (props) => {
  let isSelected = props.isSelected;
  const handleRadioButtonClick = () => {
    let parentClickElm = props.onClickEvent;
    parentClickElm();
  };

  const StyledRadioButton = styled.div`
    width: 408px;
    height: 96px;
    box-sizing: border-box;
    cursor:pointer;
    border: 2px solid ${isSelected ? "#47CCD6" : "#666666"};
    ${isSelected && "background: rgba(71, 204, 214, 0.16);"};
  `;

  const StyledRadioBtnTitle = styled.div`
    width: 305px;
    height: 24px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: 20px;
    color: #fafafa;
  `;

  const StyledRadioBtnDescription = styled.div`
    margin-left: 16px;
    margin-top: 16px;
    width: 285px;
    height: 16px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #b7b7b7;
  `;
  return (
    <div onClick={() => handleRadioButtonClick()}>
      <StyledRadioButton>
        <Row>
          <Col xs="10">
            <StyledRadioBtnTitle>{props.btnTitle}</StyledRadioBtnTitle>
          </Col>
          {isSelected ? (
            <Col
              xs="2"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={GreenTickIcon} width="20px" height="20px" />
            </Col>
          ) : null}
        </Row>

        <StyledRadioBtnDescription>
          {props.btnDescription}
        </StyledRadioBtnDescription>
      </StyledRadioButton>
    </div>
  );
};

export default RadioButton;
