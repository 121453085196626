import { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import searchIcon from "../../assets/images/searchIcon.png";
// TODO: Need to add Icon on search bar

const SearchBoxSkeleton = styled.input`
  all: unset;
  width: 276px;
  height: 52px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  color: #fafafa;
  ::placeholder {
    width: 276px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 42px;
    color: #909090;
  }
`;

const SearchBoxDataList = styled.datalist`
  width: 276px;
  height: 52px;
`;

const SearchBox = (props) => {
  const { data, filteredDataList } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  // TODO: Need to add search on change logic

  const handleSearch = (event) => {
    // setSearchTerm(event.target.value);
    // let results = [];
    // if (searchTerm.length > 0) {
    //   // NOTE: Get the device list data array
    //   results = ["apple", "mango", "grape", "poapp"].filter((item) =>
    //     item.toLowerCase().includes(searchTerm.toLowerCase())
    //   );
    // }
    // console.log("results", results);
    // setSuggestions(results);
    props.handleSearch(event.target.value);
  };

  return (
    <Col className="searchBoxSkeletonStyle">
      <Container fluid>
        <Row>
          <Col
            xs={3}
            style={{
              alignItems: "center",
              justifyContent: "end",
              display: "flex",
            }}
          >
            <img src={searchIcon} width="24px" height="24px" />
          </Col>

          <Col xs={9}>
            <SearchBoxSkeleton
              autoComplete="none"
              onChange={handleSearch}
              placeholder={props.placeholder ? props.placeholder : ""}
            />
            <SearchBoxDataList>
              {suggestions.map((e, i) => (
                <option key={i} value={e} />
              ))}
            </SearchBoxDataList>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};
export default SearchBox;
