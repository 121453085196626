import Typography from "./_typography";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import greenTick from "../../assets/images/greenTick.png";
import { useState } from "react";
import TickIcon from "../../assets/images/task_alt_white.svg";

/**
 *NOTE: Common Component
 * @description This Component is about Data Source, which user will select while Account Setup.
 * @param {*} props
 * @return {*}
 */
const DataSourceCard = (props) => {
  const { handleSourceCardSelection, isSourceSelected } = props;
  // const [isCardSelected, setCardSelected] = useState(false);

  const DataCardComponent = styled.div`
    min-width: 100%;
    height: 424px;
    cursor: ${props.disabled == false ? "pointer" : null};
    background: ${props.disabled == false && isSourceSelected
      ? "rgba(71, 204, 214, 0.16)"
      : "#313131"};
    border: ${props.disabled == false && isSourceSelected
      ? "2px solid #47CCD6"
      : "2px solid #313131"};
  `;

  const DataCardAvatar = styled.div`
    width: 212px;
    height: 212px;
    border-radius: 100px;
    background: #232323;
  `;

  return (
    <Col
      xs={12}
      className={"dataCardMainRowStyle"}
      onClick={handleSourceCardSelection}
    >
      <DataCardComponent>
        {isSourceSelected ? (
          <Row style={{ margin: "20px 0px 0px 0px" }}>
            <Col xs={10}></Col>
            <Col xs={2} className="dataCardGreenTick">
              <img src={TickIcon} width="20px" height="20px" />
            </Col>
          </Row>
        ) : null}
        <Col
          xs={12}
          className={
            isSourceSelected
              ? "dataCardMainRowStyleAvatarStyle2"
              : "dataCardMainRowStyleAvatarStyle1"
          }
        >
          <DataCardAvatar>
            <Col className="dataCardAvatar">
              {/* NOTE: Need to replace the image */}
              <img
                src={props.iconImages}
                width="109px"
                height="109px"
                alt="status"
              />
            </Col>
          </DataCardAvatar>
        </Col>
        <Col xs={12} className="dataCardText">
          <Typography
            text={props.name}
            tag={"head-x-small"}
            color={"#FAFAFA"}
          />
        </Col>
      </DataCardComponent>
    </Col>
  );
};

export default DataSourceCard;
