import styled from "styled-components";

const Typography = (props) => {
  let tag = props.tag;
  let StyledTextTag;
  switch (tag) {
    case "head-x-large":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 60px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "head-large":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "head-medium":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "head-small":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "head-x-small":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "head-x-x-small":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "sub-head-large":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "sub-head-medium":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "sub-head-small":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "p-large":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "p-bold-large":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "p-medium":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "p-bold-medium":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "p-small":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "p-bold-small":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "p-x-small":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    case "p-bold-x-small":
      StyledTextTag = styled.div`
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: 0.001em;
        color: ${props.color};
      `;
      break;

    default:
      break;
  }

  return <StyledTextTag>{props.text}</StyledTextTag>;
};

export default Typography;
