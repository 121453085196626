import styled from "styled-components";

const Button = (props) => {
  const handleButtonClick = () => {
    let onClickEvent = props.onClickEvent;
    onClickEvent();
  };

  let buttonWidth;

  switch (props.tag) {
    case "large-primary":
      buttonWidth = "408px";
      break;
    case "primary":
      buttonWidth = "240px";
      break;
    case "small-primary":
      buttonWidth = "170px";
      break;
    case "large-secondary":
      buttonWidth = "408px";
      break;
    case "secondary":
      buttonWidth = "240px";
      break;
    case "small-secondary":
      buttonWidth = "170px";
      break;
    default:
      buttonWidth = "240px";
      break;
  }

  const StyledButtonPrimary = styled.button`
    all: unset;
    width: ${props.width ? props.width : buttonWidth};
    height: 56px;
    background: ${props.disabled ? "#2A5F63" : "#47CCD6"};
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #171717;
  `;

  const StyledButtonsecondary = styled.button`
    all: unset;
    color: #fafafa;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-size: 22px;
    font-weight: 600;
    width: ${props.width ? props.width : buttonWidth};
    height: 56px;
    text-align: center;
    border: 2px solid ${props.disabled ? "#797979" : "#FAFAFA"}; ;
  `;
  return (
    <>
      {props.tag.includes("primary") && (
        <StyledButtonPrimary
          disabled={props.disabled ? props.disabled : false}
          onClick={() => handleButtonClick()}
        >
          {props.btnText}
        </StyledButtonPrimary>
      )}

      {props.tag.includes("secondary") && (
        <StyledButtonsecondary
          disabled={props.disabled ? props.disabled : false}
          onClick={() => handleButtonClick()}
        >
          {props.btnText}
        </StyledButtonsecondary>
      )}
    </>
  );
};

export default Button;
